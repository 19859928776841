<template>

  <section class="outrance" @click="onClick" v-bind:class="{ 'blurred': blurred }">
    <video autoplay playsinline @ended="onVideoEnd" poster="../assets/images/building-entry.jpg">
      <source src="../assets/video/Margaux_Matterport_Outro.mp4" type="video/mp4">
    </video>
  </section>

</template>

<script>

  export default  {
    name: 'Outrance',
    props: [],
    mounted () {

    },
    data () {
      return {
        blurred: false
      }
    },
    methods: {
      nextStep() {
        this.blurred = true;
        setTimeout(() => {
          this.$emit('next-step');
        }, 600);
      },
      onVideoEnd() {
        this.nextStep();
      },
      onClick() {
        this.nextStep();
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .outrance {
    position: relative;
    width: 100%;
    height: 100%;
    
    transition: 0.5s filter linear;

    video {
      width: 100%;
      height: 100%;
    }
  }

  .blurred {
    filter: blur(8px);
  }
</style>
