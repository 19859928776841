<template>
    <button class="btn btn-action btn-action" :class="[cssClass]" @click="buttonClicked">
        <span class="anim-circles circle-one"></span>
        <!-- <span class="anim-circles circle-two"></span> -->
        <span class="anim-circles circle-three"></span>
    </button>
</template>

<script>

  export default  {
    name: 'ActionButton',
    props: ['cssClass'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
      buttonClicked(){
        this.$emit('next-step');
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  $green: #96c11f;

  .btn{
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
  }

  .btn-action{
      background: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2vw;
      height: 2vw;
      border-radius: 100%;
      padding: 0;
  }

  .anim-circles
  {
      position: absolute;
      border: 0.2vw solid $green;
      border-radius: 100%;
      padding: 0;
  }

  .circle-one, .circle-two, .circle-three
  {
      animation: circle-animation 1s infinite;
  }

  .circle-one
  {
      width: 1vw;
      height: 1vw;
      animation-direction: alternate;
      animation-delay: .2s;
  }

  .circle-two
  {
      width: 1.5vw;
      height: 1.5vw;
      animation-delay: .4s;
      animation-direction: alternate;
  }

  .circle-three
  {
      width: 2vw;
      height: 2vw;
      animation-delay: .6s;
      animation-direction: alternate;
  }

  @keyframes circle-animation {
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }
</style>
