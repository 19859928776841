<template>
  <div id="app">
    <vue-headful
            title="the bloc PARTNERS ALPS"
            description="Welcome to the bloc PARTNERS ALPS"
        />
    <div class="app-container">
      <div class="slide-wrapper">
        <Street v-if="currentStep <= 0" :blurredAtStart="(currentStep == -1)" @next-step="nextStep"/>
        <Entrance v-if="currentStep == 1" @next-step="nextStep"/>
        <transition name="component-fade" mode="out-in">
          <Hall v-if="currentStep == 2" @next-step="nextStep" @back-to-street="exit"/>
        </transition>
        <transition name="component-fade" mode="out-in">
          <LiftEntrance v-if="currentStep == 3" @next-step="nextStep"/>
        </transition>
        <transition name="component-fade" mode="out-in">
          <Lift v-if="currentStep == 4" @next-step="goToFloor" @previous-step="goToHall"/>
        </transition>
        <transition name="component-fade" mode="out-in">
          <Floor v-if="currentStep == 5" :floorName="currentFloorName" @next-step="backToLift"/>
        </transition>
        <transition name="component-fade" mode="out-in">
          <Outrance v-if="currentStep == 6" @next-step="backToStreet"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Street from './components/Street.vue'
import Entrance from './components/Entrance.vue'
import Hall from './components/Hall.vue'
import LiftEntrance from './components/LiftEntrance.vue'
import Lift from './components/Lift.vue'
import Floor from './components/Floor.vue'
import Outrance from './components/Outrance.vue'
import vueHeadful from 'vue-headful';

export default {
  name: 'App',
  data() {
    return {
      currentStep: -1,
      lastStep: 4,
      currentFloorName: ''
    }
  },
  components: {
    vueHeadful,
    Street,
    Entrance,
    Hall,
    LiftEntrance,
    Lift,
    Floor,
    Outrance
  },
  methods: {
    nextStep(){
      if(this.currentStep < this.lastStep){
        this.currentStep++;
      }
    },
    goToFloor(floorName){
      this.currentStep++;
      this.currentFloorName = floorName;
    },
    backToLift() {
      this.currentStep = 4;
    },
    goToHall(){
      this.currentStep = 2;
    },
    exit() {
      this.currentStep = 6;
    },
    backToStreet() {
      this.currentStep = 0;
    }
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Roboto');

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
{
  opacity: 0.1;
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ccc;
  margin-top: 60px;
}

$green: #048780;

* {
  box-sizing: border-box;
}

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body{
  font-size: 14px;
  font-size: 1.4rem;
  background: #ccc;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  margin: 0;
}

.app-container{
  position: relative;
  height: 100vh;
  width: 178vh;

  @media (max-aspect-ratio: 1920/1080) {
    width: 100%;
    height: 56.25vw;
  }

  overflow: hidden;
  color: #2c3e50;
  background: #ccc;
}

.slide-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

a{
  color: $green;
}

ul{
  padding-left: 1.5rem;
}

.text-uppercase{
  text-transform: uppercase;
}

.text-center{
  text-align: center;
}

.d-flex{
  display: flex;
}

.align-items-center{
  align-items: center;
}

.justify-center{
  justify-content: center;
}

.justify-between{
  justify-content: space-between;
}

.mt-0 {
  margin-top: 0;
}

.mb-0{
  margin-bottom: 0;
}

.sidebar-title{
  sup{
    font-size: 60%;
  }
}

.list-inline{
  padding-left: 0;
  list-style: none;
  li{
    display: inline-block;
    &:not(:last-child){
      margin-right: 1rem;
    }
  }
}
</style>
