<template>
    <button class="button button-next-slide" :class="[direction]" @click="buttonClicked">
        <span class="m_scroll_arrows arrow-one"></span>
        <span class="m_scroll_arrows arrow-two"></span>
        <span class="m_scroll_arrows arrow-three"></span>
    </button>
</template>

<script>

  export default  {
    name: 'ArrowNext',
    props: ['direction'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
      buttonClicked(){
        this.$emit('next-step');
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
$green: #96c11f;

.button{
    background: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
}

.button-next-slide{
    height: 5vw;
    &.top{
      transform: rotate(-90deg);
    }
    &.left{
      transform: rotate(180deg);
    }
}

.m_scroll_arrows
{
  display: inline-block;
  width: 2vw;
  height: 2vw;
  transform: rotate(-45deg);
   
  border-right: 0.1vw solid $green;
  border-bottom: 0.1vw solid $green;
}

.arrow-one, .arrow-two, .arrow-three
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
}

.arrow-one
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

.arrow-two
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;

  margin-left: -0.5vw;
}

.arrow-three
{
  -webkit-animation-delay: .4s;
  -moz-animation-delay: .4s;
  -webkit-animation-direction: alternate;

  animation-delay: .4s;
  animation-direction: alternate;

  margin-left: -0.5vw;
}

@keyframes mouse-scroll {
  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

</style>
