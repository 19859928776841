<template>

  <div class="street" v-bind:class="{ 'blurred': blurred }" @click="onClick">
    <div class="street-content">
      <div class="street-row">
        <div class="col door-col align-items-center">
          <ArrowNext v-if="!blurred" direction="left" @next-step="nextStep" />
          <ArrowNext v-if="!blurred" @next-step="nextStep" />
        </div>
      </div>
    </div>
    <ActionZone v-if="!blurred" top="28%" left="33%" width="34%" height="64%" @button-clicked="nextStep" />
    <ActionZone id="x-ray" v-if="!blurred" glowRing="true" top="36%" left="20%" width="9%" height="15%" @button-clicked="openInNewTab('https://x-ray.ch')" />
    <ActionZone v-if="!blurred" glowRing="true" top="38%" left="71%" width="12%" height="12%" @button-clicked="openInNewTab('https://serro.at')" />
    <ActionZone v-if="!blurred" glowRing="true" top="44%" left="89%" width="10%" height="12%" @button-clicked="goToOnePagePDF" />
    <SoundButton @button-clicked="switchSound" :muted="muted" />
  </div>

  <div class="click-to-start" v-if="blurred" @click="onClick">Click to Start</div>

  <OnePagePdf v-if="showModal" doc="/static/two_pages.pdf" @close="closeModal" />

</template>

<script>
  import ArrowNext from './ArrowNext.vue';
  import ActionZone from './ActionZone.vue';
  import OnePagePdf from './OnePagePdf.vue';
  import SoundButton from './SoundButton.vue';

  export default  {
    name: 'Street',
    props: ['blurredAtStart'],
    mounted () {
      this.audio.loop = true;
      console.log('blurred: ', this.blurred);
      this.blurred = this.blurredAtStart;
      if (!this.blurred) {
        this.playSound();
      }
    },
    beforeUnmount() {
      this.pauseSound();
    },
    data () {
      return {
        audio: new Audio(require('../assets/audio/street_ambience_snippet.mp3')),
        blurred: true,
        showModal: false,
        muted: false
      }
    },
    components: {
      ArrowNext,
      ActionZone,
      OnePagePdf,
      SoundButton
    },
    methods: {
      playSound() {
        try {
          this.audio.play();
        }
        catch (e) {
          console.error("Error playing sound!",e);
        }
      },
      pauseSound() {
        try {
          this.audio.pause();
        }
        catch (e) {
          console.error("Error pausing sound!",e);
        }
      },
      nextStep(){
        this.pauseSound();
        this.$emit('next-step');
      },
      onClick() {
        if (this.blurred) {
          this.blurred = false;
          this.playSound();
          this.$emit('next-step'); //step -1 to 0
        }
      },
      goToOnePagePDF() {
        this.pauseSound();
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
        this.playSound();
      },
      openInNewTab(url) {
        window.open(url, '_blank');
      },
      switchSound() {
        if (this.muted) {
          this.muted = false;
          this.audio.volume = 1;
        }
        else {
          this.muted = true;
          this.audio.volume = 0;
        }
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">

  .street {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../assets/images/building-entry.jpg");
    background-size: contain;

    transition: 1s filter linear;
  }

  .blurred {
    filter: blur(8px);
    cursor: pointer;
  }

  .street-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32% 0 0 0;
    box-sizing: border-box;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-empty{
    flex-basis: 0;
    flex-grow: 1;
    max-width: 54%;
    flex: 0 0 54%;
  }

  .door-col{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%
  }

  .street-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media only screen and (max-width: 567px) {
      flex-direction: column;
    }
  }

  .click-to-start {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100px;
    width: 200px;

    background: #96c11f;
    color: white;
    font-size: 26px;
    border-radius: 20px;
    border: 1px solid white;
    cursor: pointer;
  }

</style>
