<template>

  <div class="lift">
    <div class="lift-img lift-inside" v-bind:class="{ 'zoom-in-panel': zoomed, 'blurred': blurred, 'x-ray': (highlighted == 'x-ray'), 'serro': (highlighted == 'serro'), 'exit': (highlighted == 'exit') }"></div>
    <ActionZone v-if="zoomed && !blurred" glowRing="true" top="50%" left="54%" width="6%" height="11%" @button-clicked="goToFloor('x-ray')" @mouse-over="highlight('x-ray')" @mouse-leave="highlight('')" />
    <ActionZone v-if="zoomed && !blurred" glowRing="true" top="48.5%" left="46.5%" width="6%" height="11%" @button-clicked="goToFloor('serro')"  @mouse-over="highlight('serro')" @mouse-leave="highlight('')" />
    <ActionZone v-if="zoomed && !blurred" glowRing="true" top="79%" left="55%" width="6%" height="8%" @button-clicked="goBack()" @mouse-over="highlight('exit')" @mouse-leave="highlight('')"/>
    <SoundButton @button-clicked="switchSound" :muted="muted" />
  </div>

</template>

<script>
import ActionZone from './ActionZone.vue'
import SoundButton from './SoundButton.vue'

  export default  {
    name: 'Lift',
    props: [],
    mounted () {
      this.liftMusic.loop = true;
      this.liftMusic.play().then(() => {
        setTimeout(() => {
          this.zoomPanel();
        }, 1000);
      });

      //preload images
      this.img1.src = require('../assets/images/lift-panel-xray.jpg');
      this.img2.src = require('../assets/images/lift-panel-serro.jpg');
      this.img3.src = require('../assets/images/lift-panel-exit.jpg');
    },
    data () {
      return {
        zoomed: false,
        liftMusic: new Audio(require('../assets/audio/lift-music.mp3')),
        doorSound: new Audio(require('../assets/audio/elevator_door.mp3')),
        blurred: false,
        muted: false,
        highlighted: '',
        img1: new Image(),
        img2: new Image(),
        img3: new Image()
      }
    },
    methods: {
      zoomPanel() {
        if (!this.zoomed) {
          this.zoomed = true;
        }
      },
      goToFloor(floorName) {
        this.doorSound.play().then(() => {
          this.blurred = true;
          setTimeout(() => {
            this.liftMusic.pause();
            this.$emit('next-step',floorName);
          }, 1500);
        });
      },
      goBack() {
        this.liftMusic.pause();
        this.$emit('previous-step');
      },
      highlight(partToHighlight) {
        this.highlighted = partToHighlight;
      },
      switchSound() {
        if (this.muted) {
          this.muted = false;
          this.liftMusic.volume = 1;
        }
        else {
          this.muted = true;
          this.liftMusic.volume = 0;
        }
      }
    },
    computed: {

    },
    beforeUnmount () {
      this.liftMusic.pause();
    },
    components: {
      ActionZone,
      SoundButton
    }
}


</script>

<style scoped lang="scss">
  .lift {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .lift-img {
    width: 100%;
    height: 100%;
  }

  .zoom-in {
    transform: scale(8);
  }

  .lift-entrance {
    background: url("../assets/images/lift-entrance.jpg");
    background-size: contain;

    transition: all 2.5s;
    transition-delay: 0.5s;
  }

  .lift-inside {
    background: url("../assets/images/lift-panel.jpg");
    background-size: contain;

    &.zoom-in-panel {
      transform: scale(1.5);

      transition: all 0.5s;
      transition-delay: 0s;
    }

    &.x-ray {
      background: url("../assets/images/lift-panel-xray.jpg");
      background-size: contain;
    }

    &.serro {
      background: url("../assets/images/lift-panel-serro.jpg");
      background-size: contain;
    }

    &.exit {
      background: url("../assets/images/lift-panel-exit.jpg");
      background-size: contain;
    }

    transition: all 2.5s;
    transition-delay: 0s;
  }

  .btn-x-ray{
    position: absolute;
    top: 27.9%;
    right: 41.4%;
  }

  .btn-serro{
    position: absolute;
    top: 26.9%;
    right: 48.5%;
  }

  .blurred {
    filter: blur(8px);
  }

</style>
