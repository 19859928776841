<template>
  <div class="entrance" @click="onClick">
    <video autoplay playsinline @ended="onVideoEnd" poster="../assets/images/building-entry.jpg">
      <source src="../assets/video/TheBloc_Alps_Intro_Intro_Companies.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
  export default  {
    name: 'Entrance',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
      nextStep() {
        this.$emit('next-step');
      },
      onVideoEnd() {
        this.nextStep();
      },
      onClick() {
        this.nextStep();
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .entrance {
    position: relative;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
    }
  }
</style>
