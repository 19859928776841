<template>

  <div class="floor">
    <div class="back-button" @click="backToLift"><img src="../assets/icons/exit-icon.png" /></div>
    <div class="matterport-showcase">
      <iframe v-if="floorName == 'x-ray'" style="overflow:hidden;height:100%;width:100%" height="100%" width="100%" src='https://my.matterport.com/show/?m=HicEHckf1ZG&play=1&qs=1&brand=0' frameborder=0 allowfullscreen allow='xr-spatial-tracking'></iframe>
      <iframe v-if="floorName == 'serro'" style="overflow:hidden;height:100%;width:100%" height="100%" width="100%" src='https://my.matterport.com/show/?m=bfeXAWprojd&play=1&qs=1&brand=0' frameborder=0 allowfullscreen allow='xr-spatial-tracking'></iframe>
    </div>
  </div>

</template>

<script>

  export default  {
    name: 'Floor',
    props: ['floorName'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
      backToLift() {
        this.$emit('next-step');
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .floor {
    .back-button {
      position: absolute;
      cursor: pointer;
      z-index: 1000;
      top: 15px;
      right: 15px;
      background: rgba(0, 0, 0, 0.5);
      width: 60px;
      height: 50px;
    }

  }
  .matterport-showcase iframe{
    min-height: 240px;
    margin: 0;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100vw;
  }

  .matterport-showcase {
    text-align: center;
    height: 100vh; 
    width: 100vw;
  }
</style>
