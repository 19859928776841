<template>

  <div class="lift-entrance">
    <div class="lift-img" v-bind:class="{ 'opened': doorOpened }"></div>
  </div>

</template>

<script>

  export default  {
    name: 'LiftEntrance',
    props: [],
    mounted () {
      this.doorOpened = false;
      setTimeout(() => {
        this.openDoor();
      }, 500);
    },
    data () {
      return {
        doorOpened: false,
        doorSound: new Audio(require('../assets/audio/elevator_door.mp3')),
      }
    },
    methods: {
        openDoor () {
          this.doorOpened = true;
          this.doorSound.play();
          setTimeout(() => {
            this.goToNextStep();
          }, 3000);
        },
        goToNextStep () {
          this.$emit('next-step');
        }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .lift-entrance {
    position: relative;
    width: 100%;
    height: 100%;

    .lift-img {
      width: 100%;
      height: 100%;

      background: url("../assets/images/lift-entrance.jpg");
      background-size: contain;

      transition: background-image 2s ease-in-out;
      transition-delay: 0.5s;
    }

    .opened {
      background: url("../assets/images/lift-entrance-opened.jpg");
      background-size: contain;
    }

  }
</style>
