<template>

  <div class="hall">
    <ActionZone top="29%" left="0%" width="3%" height="50%" @button-clicked="backToStreet" />
    <div class="back-button" @click="backToStreet"><img src="../assets/icons/exit-icon-left.png" /><div class="glow-ring"></div></div>
    <ActionButton cssClass="btn-lift" @next-step="nextStep" />
    <ArrowNext class="arrows" v-if="!blurred" direction="left" @next-step="nextStep" />
    <ActionZone top="37%" left="10%" width="6%" height="39%" @button-clicked="nextStep" />
    <Carousel cssClass="carousel-board" />
    <SoundButton @button-clicked="switchSound" :muted="muted" />
  </div>

</template>

<script>

import ActionButton from './ActionButton.vue'
import ArrowNext from './ArrowNext.vue'
import Carousel from './Carousel.vue'
import ActionZone from './ActionZone.vue'
import SoundButton from './SoundButton.vue'

  export default  {
    name: 'hall',
    props: [],
    mounted () {
      this.audio.loop = true;
      this.playSound();
    },
    beforeUnmount () {
      this.pauseSound();
    },
    data () {
      return {
        audio: new Audio(require('../assets/audio/building-lobby-ambience.mp3')),
        muted: false
      }
    },
    methods: {
      nextStep(){
        this.pauseSound();
        this.$emit('next-step');
      },
      backToStreet(){
        this.pauseSound();
        this.$emit('back-to-street');
      },
      playSound() {
        try {
          this.audio.play();
        }
        catch (e) {
          console.error("Error playing sound!",e);
        }
      },
      pauseSound() {
        try {
          this.audio.pause();
        }
        catch (e) {
          console.error("Error pausing sound!",e);
        }
      },
      switchSound() {
        if (this.muted) {
          this.muted = false;
          this.audio.volume = 1;
        }
        else {
          this.muted = true;
          this.audio.volume = 0;
        }
      }
    },
    computed: {

    },
    components: {
      ActionButton,
      ArrowNext,
      Carousel,
      ActionZone,
      SoundButton
    }
}


</script>

<style scoped lang="scss">
  .hall {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../assets/images/building-hall.jpg");
    background-size: contain;

    .back-button {
      position: absolute;
      cursor: pointer;
      z-index: 1000;
      top: 42%;
      left: 0.4%;
      background: rgba(0, 0, 0, 0.2);
      width: 2vw;
      height: 2vw;

      img {
        width: 2vw;
        height: 2vw;
      }
    }
  }

  .btn-lift{
    position: absolute;
    top: 55.5%;
    left: 18%;
  }

  .carousel-board {
    position: absolute;
    top: 5.8%;
    right: 4.6%;
  }

  .arrows {
    position: absolute;
    top: 59.5%;
    left: 42.1%;
    transform: scale(1.2) rotate(180deg) !important;
  }

    .glow-ring {
    // background: rgba(0,0,0,0.5);
    position: relative;
    height: 50%;
    width: 50%;
    border-radius: 50%;
    // margin: 50px;
    border: 2px solid rgba(150,193,31,0.5);

    &:before,
    &, span {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0%;
      left: 0%;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(150,193,31,0.5);
      animation: glow-grow 2s ease-out infinite;
    }

    &:after {
      animation-delay: .66s;
    }

    span{
      animation-delay: 1.33s;
    }
  }

  @keyframes glow-grow {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }

</style>
