<template>

  <div class="action-zone" v-bind:style="styleObject" @click="buttonClicked" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    <div v-if="glowRing" class="glow-ring">
      <span></span>
    </div>
  </div>

</template>

<script>

  export default  {
    name: 'ActionZone',
    props: ['top','left','width','height','glowRing'],
    mounted () {

    },
    data () {
      return {
        styleObject: {
          top: this.top,
          left: this.left,
          width: this.width,
          height: this.height
        }
      }
    },
    methods: {
      buttonClicked(){
        this.$emit('button-clicked');
      },
      onMouseOver() {
        this.$emit('mouse-over');
      },
      onMouseLeave() {
        this.$emit('mouse-leave');
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .action-zone {
    position: absolute;
    cursor: pointer;
    // background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }

  .glow-ring {
    // background: rgba(0,0,0,0.5);
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    // margin: 50px;
    border: 2px solid rgba(150,193,31,0.3);

    &:before,
    &, span {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0%;
      left: 0%;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(150,193,31,0.3);
      animation: glow-grow 2s ease-out infinite;
    }

    &:after {
      animation-delay: .66s;
    }

    span{
      animation-delay: 1.33s;
    }
  }

  @keyframes glow-grow {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
</style>
