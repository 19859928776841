<template>

  <div class="sound-button" @click="switchSound">
      <img v-if="muted" src="../assets/icons/unmute.png" />
      <img v-if="!muted" src="../assets/icons/mute.png" />
    </div>

</template>

<script>

  export default  {
    name: 'SoundButton',
    props: ['muted'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
      switchSound() {
        this.$emit('button-clicked');
      }
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .sound-button {
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    top: 1vw;
    left: 1vw;
    background: rgba(255, 255, 255, 0.5);
    width: 3vw;
    height: 3vw;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 2vw;
      height: 1.5vw;
    }
  }
</style>
