<template>

  <div class="carousel" :class="[cssClass]">
      <img :src="require(`@/assets/images/${images[imgIndex]}`)" @click="changeImage" />
  </div>

</template>

<script>

  export default  {
    name: 'Carousel',
    props: ['cssClass'],
    mounted () {

    },
    data () {
      return {
        images: ["ref1.png", "Serro_Ref1.png", "ref2.png", "Serro_Ref2.png", "ref3.png"],
        imgIndex: 0,
        imgName: "ref1.png"
      }
    },
    methods: {
      changeImage() {
        this.imgIndex++;
        if (this.imgIndex >= this.images.length) {
          this.imgIndex = 0;
        }
      },
      startTimer() {
        setTimeout(() => {
            this.changeImage();
            this.startTimer();
        }, 2000)
      }
    },
    computed: {

    },
    created () {
        this.startTimer();
    }
}


</script>

<style scoped lang="scss">
  .carousel {
    width: 19.6%;
    height: 65.8%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: opacity .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
  {
    opacity: 0.1;
  }
</style>
